'use strict'

###*
 # @ngdoc service
 # @name lpa.lpaIncidentTracking.factory:CommentsListFactory

 # @description

###
class CommentsListFactory
  ### @ngInject ###
  constructor: (
    $mdMedia
    $mdDialog
    $log
    Incidents
    RestUtils
    Restangular
  ) ->
    CommentsListBase = {}
    CommentsListBase.show = (incidentId, options = {} ) ->

      factory = Restangular.service('lpa/incidents/' + incidentId + '/logs')

      IncidentComments = RestUtils.getFullList factory,
        sort: 'createdAt,ASC'

      defaults =
        allowCancel: true,
        title: "app.comments",
        fullScreen: "auto",
        useFullScreen: ($mdMedia('sm') || $mdMedia('xs')),
        onCancel: ()->
          $log.debug "Closed dialog"

      options = angular.extend {}, defaults, options

      $mdDialog.show
        controllerAs: 'commentsList',
        resolve:
          options: () ->
            options
          title: () ->
            options.title
          comments: ()->
            IncidentComments

        controller: [
          '$scope'
          '$mdDialog'
          'options'
          'comments'
          ($scope, $mdDialog, options, comments) ->
            $scope.options = options
            $scope.title = options.title
            $scope.comments = comments

            $scope.hide = () ->
              $mdDialog.hide()

            $scope.cancel = () ->
              $mdDialog.cancel()

        ]
        templateUrl: 'lpa/lpa-incident-tracking/views/lpa-incident-comments-list.tpl.html'
        parent: angular.element(document.body)
        clickOutsideToClose: true
        fullscreen: false
      .then (result) ->
        options.onCancel()
      , () ->
        return options.onCancel()
        # Cancel

    return CommentsListBase

angular
  .module 'lpaIncidentTracking'
  .factory 'CommentsList', CommentsListFactory
